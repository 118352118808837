@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Inter", source-code-pro, Menlo, Monaco, Consolas,
        "Courier New", monospace;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.tinted-image {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Red tint */
}

.ant-progress-outer {
    height: 0 !important;
}

.reorder-ul,
.reorder-li {
    list-style: none;
    padding: 0;
    margin: 0;
    /*  font-family: "Poppins", sans-serif; */
    font-weight: 500;
    font-size: 24px;
}

.reorder-ul {
    position: relative;
    width: "100%";
    /* width: 300px; */
}

.reorder-li {
    border-radius: 10px;
    /*   margin-bottom: 10px; */
    width: 100%;
    /* padding: 15px 18px; */
    /* position: relative; */
    background: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    flex-shrink: 0;
    cursor: grab;
}

.reorder-ul svg {
    width: 10px;
    height: 10px;
    cursor: grab;
}

.expanded-view {
    /* height: auto;
  align-items: center;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between; */
}

.fill-available {
    width: -webkit-fill-available;
}

.flag-icon {
    border: 2px solid #000;
}

.cl-internal-180wb59 {
    display: none;
}

.cl-internal-lqfubk {
    display: none;
}

.cl-internal-11ttlho {
    display: none;
}

.cl-userButtonPopoverCard {
    width: 310px;
}

.cl-button {
    font-weight: 600;
    color: black;
}
.cl-internal-3gh7hq {
    font-weight: 600;
    color: black;
}
.cl-userButtonOuterIdentifier {
    font-weight: 600;
    color: black;
    font-size: 14px;
}

.cl-userPreviewSecondaryIdentifier {
    color: #636363;
}

.cl-rootBox:hover {
    background: transparent;
}

.cl-rootBox {
    display: flex;
}

.cl-userButtonBox {
    display: flex;
    flex-direction: row;
    gap: 5;
}

.cl-avatarBox {
    order: 0;
}

.cl-userButtonOuterIdentifier {
    text-align: start;
    padding-left: 0;
    order: last;
}

.cl-internal-2iusy0 {
    color: white;
}

/* .cl-formButtonPrimary {
  //background-color: black;
}
 */
.cl-internal-1hp5nqm {
    display: none;
}

.cl-internal-1dauvpw {
    display: none;
}

.hidden-id {
    display: none;
}

.ant-segmented-item-label {
    padding: 0 7px 0 7px !important;
}

.slider-class {
    overflow-x: visible;
    padding-left: 0px !important;
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}
/* add the code bellow */
@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .markdown > * {
        all: revert;
    }
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* ant design removing focus ring */
/* Remove focus and active rings */
.ant-color-picker-trigger.ant-popover-open,
.ant-color-picker-trigger:focus,
.ant-color-picker-trigger:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: inherit !important;
}

/* Optional: If you want to ensure no blue/default focus outline */
.ant-color-picker-trigger {
    outline: none;
}

.ant-select-selection-item {
    padding: 0 !important;
    text-align: left !important;
    align-items: start !important;
    place-items: start !important;
    font-size: "12px" !important;
}
.ant-select-arrow {
    color: #44546f !important;
}

/* Completely remove all focus, active, and hover effects */
.ant-select-selector,
.ant-select-selector:hover,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select:hover .ant-select-selector,
.ant-select:focus .ant-select-selector,
.ant-select:focus-within .ant-select-selector {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    border-width: 1px !important;
    transition: none !important;
}

/* Ensure no color changes on interaction */
.ant-select:hover,
.ant-select:focus,
.ant-select:active {
    outline: none !important;
    box-shadow: none !important;
}

/* Force default state */
.ant-select-outlined .ant-select-selector {
    border-color: transparent !important;
}

/* Remove any default hover effects */
.ant-select-outlined:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: transparent !important;
}

/* Ensure focused state doesn't change */
.ant-select-outlined.ant-select-focused .ant-select-selector {
    border-color: transparent !important;
}

.ant-color-picker-trigger-text {
    margin: 0 !important;
}

.ant-upload-list-item-name {
    text-align: left !important;
}

.ant-upload-list-item-container :hover {
    background-color: transparent !important;
}

@keyframes borderAnimation {
    0% {
        border-image-source: linear-gradient(45deg, #bc82f3, #f5b9ea, #8d99ff);
        box-shadow: 0 0 8px 2px #bc82f3, 0 0 16px 4px #f5b9ea,
            0 0 24px 6px #8d99ff;
    }
    25% {
        border-image-source: linear-gradient(45deg, #f5b9ea, #8d99ff, #aa6eee);
        box-shadow: 0 0 8px 2px #f5b9ea, 0 0 16px 4px #8d99ff,
            0 0 24px 6px #aa6eee;
    }
    50% {
        border-image-source: linear-gradient(45deg, #8d99ff, #aa6eee, #ff6778);
        box-shadow: 0 0 8px 2px #8d99ff, 0 0 16px 4px #aa6eee,
            0 0 24px 6px #ff6778;
    }
    75% {
        border-image-source: linear-gradient(45deg, #aa6eee, #ff6778, #ffba71);
        box-shadow: 0 0 8px 2px #aa6eee, 0 0 16px 4px #ff6778,
            0 0 24px 6px #ffba71;
    }
    100% {
        border-image-source: linear-gradient(45deg, #ff6778, #ffba71, #c686ff);
        box-shadow: 0 0 8px 2px #ff6778, 0 0 16px 4px #ffba71,
            0 0 24px 6px #c686ff;
    }
}

.dialog-border-animation {
    animation: borderAnimation 5s infinite alternate;
    border-style: solid;
    border-width: 0px;
    border-image-slice: 1;
    backdrop-filter: blur(23.671228408813477px);
}
